<template>
  <div class="map-wrapper">
    <div id="container"></div>
    <div class="search-wrapper">
      <van-search
        class="card-box"
        background="#fff"
        v-model.trim="searchKey"
        placeholder="请输入地点进行搜索"
        @search="onSearch">
        <i class="iconfont iconsousuo" slot="left-icon"></i>
        <!-- <div class="search-btn" slot="action" @click="onSearch">搜索</div> -->
      </van-search>
      <ul class="search-list-wrapper" v-show="showSearchList">
        <li class="border-bottom-1px van-ellipsis" v-for="item in searchList" :key="item.id" @click="handleSelectAddress(item)">{{item.district + item.name}}</li>
        <li class="no-data" v-show="searchList.length === 0">未搜索到相应结果</li>
      </ul>
    </div>
  </div>
</template>

<script>
import AMap from 'AMap'
import wx from 'wx'
import getWxSignature from '@/api/getWxSignature'
import API from '@/api/index'
import CAR from '@/assets/park-normal.png'
import CAR_ACTIVE from '@/assets/park-active.png'

import CYCLE from '@/assets/cycle-normal.png'
import CYCLE_ACTIVE from '@/assets/cycle-active.png'

import WC from '@/assets/wc-normal.png'
import WC_ACTIVE from '@/assets/wc-active.png'
import STATION from '@/assets/store-normal.png'
import STATION_ACTIVE from '@/assets/store-active.png'
import LOCATION from '@/assets/location.png'
import { AMAP_SERVE_KEY } from '@/configs/config'
const { searchAddressByKey, lnglatToAddress, searchPlaceAround, /* batchAmap, */ getPoiCollects } = API

export default {
  name: 'MyMap',
  props: {
    // 搜索周边的类型
    aroundType: {
      type: [Number, String],
      default: ''
    },
    showAreaSearch: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      map: {},
      searchKey: '',
      searchList: [],
      showSearchList: false,
      currentLocation: [0, 0], // 所选位置坐标
      collectData: [] // 收藏的数据
    }
  },
  watch: {
    currentLocation (val) {
      let [lng, lat] = val
      let lnglat = new AMap.LngLat(lng, lat)
      this.map.setCenter(lnglat)
      this.postionMarker.setPosition(lnglat)

      lnglatToAddress({
        key: AMAP_SERVE_KEY,
        location: `${lng},${lat}`
      }).then(res => {
        let address = ''
        let street = ''
        if (res.info === 'OK') {
          address = res.regeocode.formatted_address
          street = res.regeocode.addressComponent.township
        }
        this.$emit('addressChange', {
          longitude: lng,
          latitude: lat,
          street,
          address
        })
      })
    }
  },
  created () {
    getWxSignature(['getLocation', 'openLocation'], () => {
      this.getGeoLocation()
    })
  },
  mounted () {
    this.map = new AMap.Map('container', {
      resizeEnable: true, // 是否监控地图容器尺寸变化
      mapStyle: 'amap://styles/whitesmoke',
      cennter: this.currentLocation,
      zoom: 17
    })

    // 当前位置点位
    this.postionMarker = new AMap.Marker({
      offset: new AMap.Pixel(-12, -24),
      anchor: 'center',
      icon: new AMap.Icon({
        size: new AMap.Size(24, 24),
        image: LOCATION,
        imageSize: new AMap.Size(24, 24)
      }),
      map: this.map
    })

    this.bindMapEvent()
  },
  methods: {
    // 定位
    getGeoLocation () {
      wx.getLocation({
        type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
        success: ({ latitude, longitude }) => {
          this.currentLocation = [longitude, latitude]
          setTimeout(() => {
            this.showAreaSearch && this.areaSearch()
          }, 20)
        }
      })
    },
    // 开始导航
    handleNavigate ({
      latitude,
      longitude,
      name,
      address
    }) {
      // 微信在Android和IOS上，在使用openLocation的时候是不一样的。
      // 在Android上经纬度可以使浮点数可以使字符串。但是在IOS上必须是浮点数，否则打不卡地图。
      wx.openLocation({
        latitude: parseFloat(latitude), // 纬度，浮点数，范围为90 ~ -90
        longitude: parseFloat(longitude), // 经度，浮点数，范围为180 ~ -180。
        name, // 位置名
        address // 地址详情说明
      })
    },
    // 绑定地图事件
    bindMapEvent () {
      this.map.on('click', (e) => {
        // 是否来自点击图标的事件
        if (this.isMarkerClick) {
          this.isMarkerClick = false
        } else {
          let { lnglat } = e
          this.currentLocation = [lnglat.getLng(), lnglat.getLat()]
          this.hideSearchList()
        }
      })
    },
    // 搜索
    async onSearch () {
      let res = await searchAddressByKey({
        key: AMAP_SERVE_KEY,
        keywords: this.searchKey,
        location: this.currentLocation.join(',')
      })
      this.showSearchList = true
      if (res.info === 'OK') {
        this.searchList = res.tips.filter(item => {
          // 筛选有经纬度的数据
          if (item.location.indexOf(',') > -1) {
            return true
          }
        })
      }
    },
    // 选择搜索地址时
    handleSelectAddress (data) {
      let [lng, lat] = data.location.split(',')
      this.currentLocation = [lng, lat]
      this.map.setCenter(new AMap.LngLat(lng, lat))
      this.hideSearchList()
    },
    // 隐藏搜索列表
    hideSearchList () {
      this.showSearchList = false
    },
    // 区域周边搜索
    async areaSearch (radius = 500, page = 1) {
      return new Promise(async (resolve, reject) => {
        let location = this.currentLocation.join(',')
        let searchType = '' // 传给高德的type
        radius = Number(radius) || 500
        let style = 4 // 图标样式

        if (this.aroundType === 1) {
          searchType = '公共厕所'
          style = 0
        } else if (this.aroundType === 2) {
          searchType = '公共停车场'
          // radius = 2000
          style = 2
        } else if (this.aroundType === 3) {
          searchType = '公共自行车'
          style = 6
        }

        let total = 0
        // let offset = 20
        let allData = []

        let query = {
          key: AMAP_SERVE_KEY,
          location,
          types: searchType,
          radius,
          page
        }

        let [res, collectRes] = await Promise.all([searchPlaceAround(query), getPoiCollects({ types: this.aroundType })])
        if (collectRes.success) {
          this.collectData = collectRes.data
        }

        if (res.info === 'OK') {
          total = res.count * 1
          allData = allData.concat(this.transformPoisData(res.pois, style))
          // this.addMassMarks(allData, style)

          this.$emit('areaSearch', {
            total,
            data: allData
          })
          resolve(true)
        } else {
          reject(new Error('error'))
        }
      })
    },
    // 转换poi数据
    transformPoisData (data, style) {
      return data.map(item => {
        if (typeof item.address !== 'string') {
          item.address = ''
        }
        let [longitude, latitude] = item.location.split(',')
        let distance = AMap.GeometryUtil.distance(this.currentLocation, [longitude, latitude]).toFixed(0)
        let index = this.collectData.findIndex(collect => collect.poiId === item.id)

        return {
          ...item,
          longitude,
          latitude,
          distance,
          style,
          isFavorite: index > -1,
          lnglat: [longitude, latitude]
        }
      })
    },
    // 创建海量点
    addMassMarks (data, styleType) {
      let styleOpt = {
        anchor: new AMap.Pixel(12, 12),
        size: new AMap.Size(32, 39)
      }
      let activeOpt = {
        anchor: new AMap.Pixel(12, 12),
        size: new AMap.Size(44, 53)
      }
      let style = [{
        url: WC,
        ...styleOpt
      }, {
        url: WC_ACTIVE,
        ...activeOpt
      }, {
        url: CAR,
        ...styleOpt
      }, {
        url: CAR_ACTIVE,
        ...activeOpt
      }, {
        url: STATION,
        ...styleOpt
      }, {
        url: STATION_ACTIVE,
        ...activeOpt
      }, {
        url: CYCLE,
        ...styleOpt
      }, {
        url: CYCLE_ACTIVE,
        ...activeOpt
      } ]

      if (this.mass) {
        this.mass.setData(data)
        // this.mass.setStyle(style[styleType])
      } else {
        this.mass = new AMap.MassMarks(data, {
          zIndex: 111,
          cursor: 'pointer',
          style
        })

        this.mass.on('click', this.handleMassClick)

        this.mass.setMap(this.map)
      }
    },
    // 海量点点击事件
    handleMassClick (e) {
      if (this.lastPoint) {
        this.lastPoint.style--
      }
      this.isMarkerClick = true
      e.data.style++
      this.lastPoint = e.data

      this.setZoomAndCenter(e.data.longitude, e.data.latitude)
      this.$emit('showPoint', e.data)
    },
    // 移动地图
    setZoomAndCenter (lng, lat, zoom = 19) {
      this.map.setZoomAndCenter(zoom, new AMap.LngLat(lng, lat))
    }
  }
}
</script>

<style lang="scss" scoped>
  .map-wrapper{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .search-wrapper{
      position: absolute;
      width: 700px;
      top: 42px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
      .card-box{
        margin-bottom: 24px;
        padding: 20px 28px;
        padding-right: 0;
        height: 80px;
        border-radius: 24px;
        box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.08);
        .van-search__content{
          width: calc(100% - 20px);
          flex: inherit;
        }
        .iconfont{
          font-size: 28px;
          color: #999;
        }
      }
      .iconfont{
        font-size: 32px;
        color: #99A7AE;
      }
      .search-btn{
        width:117px;
        height:63px;
        background:linear-gradient(247deg,rgba(17,123,238,1) 0%,rgba(5,102,206,1) 100%);
        border-radius:8px;
        font-size: 28px;
        text-align: center;
        line-height: 63px;
        color: #fff;
      }
      .search-list-wrapper{
        li{
          padding: 16px;
          background: #fff;
          font-size: 30px;
          color: #333;
          &.no-data{
            text-align: center;
          }
        }
      }

    }
  }
  #container{
    width: 100%;
    height: 100%;
  }
</style>

<style lang="scss">
  .map-wrapper{
    .van-search__content{
      color: #BDC3C6;
      background: #fff;
      padding: 0;
    }
  }
</style>
